import styled from 'styled-components';
import { mediaQuery, spacing } from '../../../assets/theme';
import { Row } from '../../Layout/Flex';
import { H3 } from '../../atoms/Text';
import { ItemRow, ImageBox } from '../../molecules/Show/styles';

export const Wrapper = styled.div`
  display: flex;

  ${mediaQuery.lessThan('md')`
    flex-direction: column;
  `}
`;

export const ShowsBlock = styled.div`
  margin-bottom: ${spacing[6]}px;
`;

export const ShowsTitle = styled(H3)`
  border-bottom: ${props => (props.isLast ? 'none' : `1px solid ${props.theme?.foreground}`)};
  padding: 16px;
`;

export const StyledImageBox = styled(ImageBox)`
  /* ${mediaQuery.lessThan('md')`
    margin: 0;
  `} */
`;

export const AboutTitle = styled(H3)`
  margin-bottom: ${spacing[4]}px;
  ${mediaQuery.greaterThan('md')`
    padding: 0 0 0 ${spacing[3]}px;
  `};
`;

export const ShowsList = styled.ul``;

export const ShowRow = styled(ItemRow)`
  border-bottom: ${props => (props.isLast ? 'none' : `1px solid ${props.theme?.foreground}`)};
`;
