import styled from 'styled-components';
import { string } from 'prop-types';

const Cover = styled.div`
  background-image: url(${props => props.imgSrc});
  background-size: ${props => props.mode || 'cover'};
  background-position: center;
`;

Cover.propTypes = {
  imgSrc: string,
  mode: string,
};

export default Cover;
