import React from 'react';
import { fr, enGB } from 'date-fns/locale';
import styled, { css, withTheme } from 'styled-components';
import { Row } from '../../Layout/Flex';
import { spacing, mediaQuery, textSizes, colours } from '../../../assets/theme';
import Cover from '../../atoms/Cover';
import { usePlaybackStore, usePlayerStore, useLangStore } from '../../../store';
import { P, Span } from '../../atoms/Text';
import Tag from '../../atoms/Tag';
import PlaySVG from '../../icons/Play';
import StopSVG from '../../icons/Stop';
import ParticleLoader from '../../atoms/ParticleLoader';
import { useTranslation } from '../../../hooks/TranslationHooks';
import { format, isBefore } from 'date-fns';
import Link from '../../atoms/Link';
import { slugify } from '../../../helpers/string';

export const ShowWrapper = styled.div`
  opacity: ${props => (props.isPassed ? 0.5 : 1)};
  border-bottom: ${props => (props.isLast ? 'none' : `1px solid ${props.theme?.foreground}`)};
`;

export const ItemRow = styled(Row)`
  display: flex;
  padding: ${spacing[3]}px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  justify-content: space-between;
`;

export const Block = styled.div`
  flex: ${props => props.flex ?? 1};
  ${props =>
    props.align === 'right' &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`;

export const DetailsBox = styled.div`
  display: flex;
  padding-bottom: ${spacing[3]}px;

  ${mediaQuery.lessThan('md')`
    flex-direction: column;
  `}
`;

export const DescriptionBox = styled.div`
  padding: ${props => (props.noImage ? `${spacing[3]}px 0` : `${spacing[3]}px`)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  ${mediaQuery.lessThan('md')`
    padding: ${spacing[3]}px 0;
  `}
`;

export const TagRow = styled.div`
  padding: 0 0 0 ${spacing[3]}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: ${props => (props.last ? 0 : spacing[2])}px;
  ${mediaQuery.lessThan('md')`
    padding: 0;
  `}
`;

export const SpacedTag = styled(Tag)`
  margin: 0 ${spacing[1]}px ${spacing[1]}px 0;
`;

export const ImageCover = styled(Cover)`
  margin: ${spacing[3]}px;
  flex: 1;
  padding-bottom: 50%;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Video = styled.video`
  width: 100%;
  height: auto;
`;

export const ImageBox = styled.div`
  margin: ${spacing[3]}px 0 ${spacing[3]}px ${spacing[3]}px;
  flex: 1;

  ${mediaQuery.lessThan('md')`
    margin: ${spacing[3]}px 0;
  `}
`;

const HoverBox = styled.div`
  stroke: ${props => props.theme.foreground};
  &:hover svg path {
    fill: ${props => props.theme.foreground} !important;
  }
  svg path {
    transition: fill 0.3s ease-in-out;
  }
`;

export const RightContents = ({ isArchive, time, mixcloudslug }) => {
  const setPlaybackSlug = usePlaybackStore(({ setSlug }) => setSlug);
  const setIsPlaying = usePlayerStore(props => props.setIsPlaying);
  const playbackSlug = usePlaybackStore(({ slug }) => slug);
  const isLoading = usePlaybackStore(store => store.isLoading);
  const setIsLoading = usePlaybackStore(store => store.setIsLoading);

  const playArchive = slug => {
    setIsLoading(true);
    setPlaybackSlug(slug);
    setIsPlaying(false);
  };

  const stopArchive = () => {
    setPlaybackSlug('');
  };

  if (!isArchive) {
    return (
      <P size={textSizes.lg} align="right">
        {time}
      </P>
    );
  }

  if (playbackSlug === mixcloudslug && isLoading) {
    return <ParticleLoader particles={10} size={24} />;
  }

  return playbackSlug === mixcloudslug ? (
    <HoverBox onClick={() => stopArchive()}>
      <StopSVG colour="transparent" />
    </HoverBox>
  ) : (
    <HoverBox onClick={() => playArchive(mixcloudslug)}>
      <PlaySVG colour="transparent" />
    </HoverBox>
  );
};

export const RenderHosts = ({ hosts, name, padded, prepend, linkHosts }) => {
  return hosts?.length === 1 && hosts[0]?.Name === name ? null : (
    <P
      size={textSizes.lg}
      css={`
        padding: ${padded ? '0 16px' : 0};
      `}
    >
      {prepend}
      {hosts.map((host, i) => getHosts(host, i, hosts.length, linkHosts))}
    </P>
  );
};

const getHosts = (host, index, length, linkHosts) => {
  if (index + 1 === length && index !== 0) {
    return (
      <Span key={host.Name}>
        {' & '}
        <HostItem linkHosts={linkHosts} slug={`/hosts/${slugify(host.Name)}`}>
          {host.Name}
        </HostItem>
      </Span>
    );
  }

  return length === 1 || index === length - 2 ? (
    <HostItem linkHosts={linkHosts} slug={`/hosts/${slugify(host.Name)}`} key={host.Name}>
      {host.Name}
    </HostItem>
  ) : (
    <Span key={host.Name}>
      <HostItem linkHosts={linkHosts} slug={`/hosts/${slugify(host.Name)}`}>
        {host.Name}
      </HostItem>
      {', '}
    </Span>
  );
};

const HostItem = withTheme(({ children, linkHosts, slug, theme }) =>
  linkHosts ? (
    <Link to={slug}>
      <Span colour={theme?.highlight}>{children}</Span>
    </Link>
  ) : (
    <Span>{children}</Span>
  ),
);

export const PlayBackShow = withTheme(({ mixcloudSlug, theme }) => {
  const currentLang = useLangStore(props => props.currentLang);
  const listenBack = useTranslation({ key: 'listenBack' })[currentLang];
  const setPlaybackSlug = usePlaybackStore(({ setSlug }) => setSlug);
  const setIsPlaying = usePlayerStore(props => props.setIsPlaying);
  const playbackSlug = usePlaybackStore(({ slug }) => slug);
  const setIsLoading = usePlaybackStore(store => store.setIsLoading);

  const playArchive = () => {
    setIsLoading(true);
    setPlaybackSlug(mixcloudSlug);
    setIsPlaying(false);
  };

  const stopArchive = () => {
    setPlaybackSlug('');
  };

  return (
    <Row
      css={`
        margin-bottom: ${spacing[4]}px;
        ${mediaQuery.greaterThan('lg')`
          padding: 0 16px;
        `}
      `}
      data-hoverable
      align="center"
      onClick={playbackSlug ? stopArchive : playArchive}
    >
      <PlaySVG
        colour="transparent"
        stroke={theme.foreground}
        css={`
          margin-right: 8px;
        `}
      />
      <P>{listenBack}</P>
    </Row>
  );
});

export const BroadcastDateTime = ({ dateTime: { startTime, endTime }, padded }) => {
  const currentLang = useLangStore(props => props.currentLang);
  const dateLocale = currentLang === 'fr' ? fr : enGB;
  const now = new Date();

  const firstBroadcast = useTranslation({ key: 'firstBroadcastDate' })[currentLang];
  const nextBroadcast = useTranslation({ key: 'nextBroadcast' })[currentLang];

  const isPast = isBefore(new Date(endTime), now);

  return (
    <P
      css={`
        padding: ${padded ? '0 16px' : 0};
        margin-bottom: ${spacing[4]}px;
      `}
    >
      <Span weight="800">{isPast ? firstBroadcast : nextBroadcast} </Span>
      <span>
        {`${format(new Date(startTime), 'PPP', { locale: dateLocale })}, ${format(
          new Date(startTime),
          'kk:mm',
        )}—${format(new Date(endTime), 'kk:mm')}`}
      </span>
    </P>
  );
};
