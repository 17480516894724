import React, { useEffect } from 'react';
import { format, isAfter, isBefore, startOfToday } from 'date-fns';
import { css, withTheme } from 'styled-components';
import { useWindowSize } from 'react-use';

import Page from '../../organisms/Page';
import ContentsWrapper from '../../Layout/ContentsWrapper';
import Markdown from '../../atoms/Markdown';
import { P, H1, H3, Span } from '../../atoms/Text';
import { BroadcastDateTime, DescriptionBox, Image } from '../../molecules/Show/styles';

import {
  ShowsBlock,
  Wrapper,
  ShowRow,
  ShowsTitle,
  ShowsList,
  StyledImageBox as ImageBox,
  AboutTitle,
} from './styles';

import { colours, screenSizes, textSizes } from '../../../assets/theme';
import { useDeviceStore, useLangStore } from '../../../store';
import { useTranslation } from '../../../hooks/TranslationHooks';
import { capitaliseFirstLetter, slugify } from '../../../helpers/string';
import Link from '../../atoms/Link';

const frenchVowels = ['a', 'e', 'i', 'o', 'u', 'h', 'y'];

const HostPage = withTheme(
  (
    {
      pageContext: {
        host: {
          Name: hostName,
          image,
          bio,
          fields: { shows },
        },
      },
      theme,
    },
    ...pageData
  ) => {
    const lang = useLangStore(store => store.currentLang);
    const translations = useTranslation();
    const now = new Date();
    const isMobile = useDeviceStore(store => store.isMobile);

    const md = `
  ${bio ?? translations.noHostBio[lang]}
  `;

    const pastShows = sortByDate(
      shows
        .map(item => {
          const newItem = item;
          const { broadcast_start_time: startTime, broadcast_end_time: endTime, active } = newItem;
          const startDay = format(new Date(startTime), 'yyyy/M/d');
          const datestring = `${startDay} ${endTime?.substring(0, 5)}`;
          const broadcastEnd = new Date(datestring);

          newItem.broadcastEnd = broadcastEnd;

          return active && isBefore(broadcastEnd, now) ? newItem : null;
        })
        .filter(item => item),
    );

    const futureShows = shows.filter(({ broadcast_end_time: endTime, active }) => {
      return active && isAfter(new Date(endTime), now);
    });

    const startsWithVowel = frenchVowels.includes(slugify(hostName.substring(0, 1)));

    const aboutText = startsWithVowel ? translations.aboutVowel[lang] : translations.about[lang];
    const showsText = startsWithVowel ? translations.showsByVowel[lang] : translations.showsBy[lang];
    const nextBroadcastText = translations.nextBroadcast[lang];

    return (
      <Page title={{ en: hostName, fr: hostName }} metaImg={image?.publicURL} backTo="/hosts" data={pageData}>
        <ContentsWrapper
          css={`
            position: relative;
            padding: 0px;
          `}
        >
          <Wrapper>
            {isMobile && <H1>{hostName}</H1>}
            <>
              {image && (
                <ImageBox>
                  <Image src={image?.publicURL} />
                </ImageBox>
              )}
            </>
            <DescriptionBox noImage={!image}>
              <div>
                <AboutTitle>
                  {capitaliseFirstLetter(aboutText)}
                  {hostName}
                </AboutTitle>
                <Markdown>{md}</Markdown>
              </div>
              {futureShows?.length ? (
                <P>
                  {nextBroadcastText}{' '}
                  <Link to={`/shows/${futureShows[0].slug}`} colour={theme?.highlight}>
                    {format(new Date(futureShows[0].broadcast_start_time), 'dd.MM.yy')}
                  </Link>
                </P>
              ) : null}
            </DescriptionBox>
          </Wrapper>

          {pastShows?.length ? (
            <ShowsBlock>
              <ShowsTitle>
                {capitaliseFirstLetter(showsText)}
                {hostName}
              </ShowsTitle>
              <ShowsList>
                {pastShows.map((show, index) => (
                  <Show
                    key={show.title}
                    title={show.title}
                    slug={`/shows/${show.slug}`}
                    isLast={index === pastShows.length - 1}
                    date={show.broadcast_start_time}
                  />
                ))}
              </ShowsList>
            </ShowsBlock>
          ) : null}
        </ContentsWrapper>
      </Page>
    );
  },
);

const Show = ({ title, slug, date, isLast }) => (
  <Link
    to={slug}
    css={css`
      border-bottom: ${props => (props.isLast ? 'none' : `1px solid ${props.theme?.foreground}`)};
    `}
  >
    <ShowRow isVisible isLast={isLast} data-hoverable as="li">
      <P size={textSizes.lg}>{title}</P>
      <P size={textSizes.lg}>{format(new Date(date), 'dd.MM.yy')}</P>
    </ShowRow>
  </Link>
);

const sortByDate = array =>
  array.sort((a, b) => {
    return new Date(b.broadcast_start_time) - new Date(a.broadcast_start_time);
  });

HostPage.propTypes = {
  // pageContext: shape({
  //   host: { Name: hostName, image },
  //   slug,
  // }),
};

export default HostPage;
